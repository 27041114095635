import Vue from "vue";
import { groupBy, uniq, uniqBy, debounce } from "lodash";
import DataService from "../services/data";
import Stats from "@/utils/stats.js";

let _Stats = new Stats();
const stats = (samples, fname) => _Stats && _Stats.calc(samples, fname);

// original https://github.com/BorisChumichev/everpolate/blob/master/lib/linear.js
const interpolate = (x, x0, y0, x1, y1) => {
  var a = (y1 - y0) / (x1 - x0);
  var b = -a * x0 + y0;
  return a * x + b;
};

const findIntervalBorderIndex = (point, intervals, useRightBorder) => {
  if (point < intervals[0]) return 0;
  if (point > intervals[intervals.length - 1]) return intervals.length - 1;
  var indexOfNumberToCompare,
    leftBorderIndex = 0,
    rightBorderIndex = intervals.length - 1;
  while (rightBorderIndex - leftBorderIndex !== 1) {
    indexOfNumberToCompare =
      leftBorderIndex + Math.floor((rightBorderIndex - leftBorderIndex) / 2);
    point >= intervals[indexOfNumberToCompare]
      ? (leftBorderIndex = indexOfNumberToCompare)
      : (rightBorderIndex = indexOfNumberToCompare);
  }
  return useRightBorder ? rightBorderIndex : leftBorderIndex;
};

// generate new array with extrapolated points
const linearInterpolation = (
  pointsToEvaluate,
  functionValuesX,
  functionValuesY
) => {
  if (pointsToEvaluate.length <= 2) return functionValuesY;
  var results = [];
  (pointsToEvaluate || []).forEach((point) => {
    var index = findIntervalBorderIndex(point, functionValuesX);
    if (index == functionValuesX.length - 1) index--;
    results.push(
      interpolate(
        point,
        functionValuesX[index],
        functionValuesY[index],
        functionValuesX[index + 1],
        functionValuesY[index + 1]
      )
    );
  });
  return results;
};

// generate new array filling with empty values the last value
const fillList = (refLst, lst, leave_them_empty) => {
  if (!refLst.length || !lst.length || refLst.length < lst.length) return lst;
  const defSample = (data_id, time, date_time) => {
    return {
      data_id: data_id,
      time: time,
      date_time: date_time,
      value: undefined
    };
  };
  let result = [];
  let data_id = lst[0].data_id;
  let lst_cpy = [...lst];
  refLst.forEach((ref, i) => {
    let sample = defSample(data_id, ref.time, ref.date_time);
    while ((lst_cpy.length && ref.time >= lst_cpy[0].time)) {
      sample.value = lst_cpy.shift().value;
    }
    if (sample.value === undefined) {
      sample.value = (!leave_them_empty && result.length) ? result[result.length - 1].value : "";
    }
    result.push(sample);
  });
  return result;
};

const initHistoryData = () => {
  return {
    samples: [],
    stats: (_Stats || new Stats()).calc([])
  };
};

const generate = (context, ids, samples) => {
  let _NaN = {};
  let entries = {};
  ids.forEach((id) => {
    let min = undefined;
    let max = undefined;
    let sum = 0;
    let history = initHistoryData();
    if (samples && samples.length && typeof samples == "object") {
      history.samples = samples
        .filter((item) => {
          if (item.data_id == id) {
            if (isNaN(parseFloat(item.value))) {
              _NaN[item.data_id] = true;
            } else {
              item.value = parseFloat(item.value);
            }
            if (min === undefined || item.value < min) {
              min = item.value;
            }
            if (max === undefined || item.value > max) {
              max = item.value;
            }
            sum += _NaN[item.data_id] ? 0 : item.value || 0;
            item.time = new Date(item.date_time).getTime(); // virtual property
            return true;
          }
          return false;
        })
        .sort((a, b) => a.time - b.time);
      let cnt = history.samples.length;
      history.stats.count = cnt;
      if (cnt) {
        history.stats.minimum = min;
        history.stats.maximum = max;
        history.stats.sum = sum;
        history.stats.average = cnt > 0 ? sum / cnt : 0;
        history.stats.first = history.samples[0].value;
        history.stats.last = history.samples[cnt - 1].value;
        history.stats.diff = history.stats.last - history.stats.first;
        history.stats.balance =
          cnt > 1 ? history.stats.diff : history.stats.first;
        history.stats.amplitude = max - min;
      }
    }
    entries[id] = history;
  });
  context.commit("SET_ENTRIES", entries);
  context.commit("SET_PENDING", { ids: ids, del: true });
};

const buildQuery = (ids, start, end) => ({
  data_ids: (ids && ids.join(",")) || "",
  start:
    (start &&
      start
        .clone()
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss")) ||
    null,
  end:
    (end &&
      end
        .clone()
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss")) ||
    null
});

const tsFilter = (context, samples) => {
  if (!context.state.filter.data_id) return samples;
  let values = (context.state.filter.values || [])
    .map((i) => `${i}`)
    .filter((i) => i != "");
  if (!values.length) return context.state.filter.isRequired ? [] : samples;
  let ts = groupBy(samples, "date_time");
  let chk = false;
  let lst = [];
  for (var k in ts) {
    chk = ts[k].some(
      ({ data_id, restore_value }) =>
        parseInt(data_id) == context.state.filter.data_id &&
        values.indexOf(restore_value) >= 0
    );
    if (chk) {
      lst = lst.concat(ts[k]);
    }
  }
  return lst;
};

// store
const initialState = () => ({
  interval: null,
  createdAt: "",
  entries: {},
  process: "idle",
  pending: [],
  ready: {},
  simulationDataList: [],
  filter: {
    data_id: "",
    isRequired: true,
    values: []
  }
});

const mutations = {
  RESET(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_INTERVAL(state, entry) {
    let interval = null;
    if (entry) {
      interval = {
        start: entry.start ? entry.start.clone().set("second", 0) : null,
        end: entry.end ? entry.end.clone().set("second", 59) : null
      };
    }
    Vue.set(state, "interval", interval);
    if (_Stats) _Stats = null;
    _Stats = new Stats();
  },
  SET_CREATED_AT(state, entry) {
    state.createdAt = entry;
  },
  SET_ENTRIES(state, entry) {
    Object.keys(entry || {}).forEach((id) => {
      Vue.set(state.entries, id, entry[id]);
    });
  },
  SET_PENDING(state, entry) {
    let lst = JSON.parse(JSON.stringify(state.pending));
    entry.ids.forEach((id) => {
      if (!entry.del) {
        lst.filter((i) => i != id);
        lst.push(id);
        Vue.set(state.ready, id, false);
      } else {
        lst = lst.filter((i) => i != id);
        Vue.set(state.ready, id, true);
      }
    });
    // remove duplicated ones
    state.pending = lst.filter((v, x, l) => l.indexOf(v) === x);
  },
  TOGGLE_SIMULATION_DATA(state, dataId) {
    let lst = state.simulationDataList || [];
    let i = lst.indexOf(dataId);
    if (i >= 0) {
      lst.splice(i, 1);
    } else {
      lst.push(dataId);
    }
    Vue.set(state, "simulationDataList", lst);
  },
  RESET_DATA(state, dataId) {
    Vue.set(
      state,
      "simulationDataList",
      (state.simulationDataList || []).filter((id) => id != dataId)
    );
    Vue.set(
      state,
      "pending",
      (state.pending || []).filter((id) => id != dataId)
    );
    Vue.delete(state.entries, dataId);
  },
  SET_FILTER_VALUES(state, value) {
    state.filter.values = value && value.length ? value : [];
  },
  SET_FILTER_DATA_ID(state, value) {
    state.filter.data_id = value;
  },
  SET_FILTER(state, value) {
    Vue.set(state, "filter", { ...initialState().filter, ...value });
  }
};

const actions = {
  setInterval(context, entry) {
    context.state.api_samples = null;
    context.commit("SET_INTERVAL", entry);
  },
  fetch(context, dataIdList) {
    if (!context.state.interval) {
      return;
    }

    // remove duplicated ones
    let ids = (dataIdList || [])
      .filter((v, x, l) => l.indexOf(parseInt(v)) === parseInt(x))
      .map((id) => parseInt(id));
    // if any item from the list is already pending remove it from the list
    if (context.state.pending.length) {
      ids = ids.filter((id) => context.state.pending.indexOf(id) == -1);
    }
    if (!ids.length) return; // empty list provided or they are alredy being fetching
    // if there is a filter data_id add it
    if (context.state.filter.data_id) {
      ids.push(context.state.filter.data_id);
    }
    context.commit("SET_PENDING", { ids: ids });
    context.state.delayedFetch =
      context.state.delayedFetch ||
      debounce(() => {
        let ids = context.state.pending;
        let query = buildQuery(
          ids,
          context.state.interval.start,
          context.state.interval.end
        );
        context.state.query = query; // not reactive
        var srv = new DataService();
        srv.history(query).then((samples) => {
          if (typeof samples == "object") {
            context.state.api_samples = {
              ...(context.state.api_samples || {}),
              ...groupBy(samples || [], "data_id")
            };
            for (var data_id in context.state.api_samples || {}) {
              context.state.api_samples[data_id] = uniqBy(
                context.state.api_samples[data_id],
                "date_time"
              );
            }
            if (context.state.filter.data_id) {
              context.state.samples = samples; // it is not reactive
              samples = tsFilter(context, samples);
            }
            // it is not reactive
            context.commit("SET_CREATED_AT", moment());
            generate(context, ids, samples);
          } else {
            // context.state.samples = null; // non reactive
            context.commit("SET_PENDING", { ids: ids, del: true });
          }
        });
      }, 1000);
    context.state.delayedFetch();
  },
  fetchFitInTimeWindow(context, dataIdList) {
    if (!context.state.interval) {
      return;
    }
    // remove duplicated ones
    let ids = (dataIdList || []).filter((v, x, l) => l.indexOf(v) === x);
    // if any item from the list is already pending remove it from the list
    if (context.state.pending.length) {
      ids = ids.filter((id) => context.state.pending.indexOf(id) == -1);
    }
    if (!ids.length) return; // empty list provided or they are alredy being fetching
    let query = buildQuery(
      ids,
      context.state.interval.start,
      context.state.interval.end
    );
    context.commit("SET_PENDING", { ids: ids });
    var srv = new DataService();
    srv.history(query, true).then((samples) => {
      context.commit("SET_CREATED_AT", moment());
      generate(context, ids, samples);
    });
  },
  simulate(context, dataId) {
    context.commit("TOGGLE_SIMULATION_DATA", dataId);
    // remove duplicated ones
    let ids = (context.state.simulationDataList || []).filter(
      (v, x, l) => l.indexOf(v) === x
    );
    // if any item from the list is already pending remove it from the list
    if (context.state.pending.length) {
      ids = ids.filter((id) => context.state.pending.indexOf(id) == -1);
    }
    if (!ids.length) {
      for (const id in context.state.entries || {}) {
        ids.push(id);
      }
      context.commit("SET_PENDING", { ids: ids });
      setTimeout(() => {
        context.commit("RESET");
        // context.commit("SET_PENDING", { ids: ids, del: true });
      }, 0);
      return;
    }
    let hasNew = false;
    let now = moment();
    let interval = {
      start: moment().subtract(2, "days").startOf("day"),
      end: moment()
    };
    let diffMin = interval.end.diff(interval.start, "minutes");
    let samples = [];
    const rnd = (min, max) => Math.floor(Math.random() * (((max ?? "") === "" ? 100 : max) - ((min ?? "") === "" ? 0 : min) + 1) + (((min ?? "") === "" ? 0 : min)));
    ids.forEach((id) => {
      var data = (context.rootGetters["dashboard/dataList"] || []).find(
        (item) => item.id == id
      );
      let type = "float";
      if (
        data &&
        (data?.memory_type?.name || "").match(/(bool|coil|input status)/gi) !=
        null
      ) {
        type = "boolean";
      }
      if (
        id in context.state.entries &&
        (context.state.entries[id]?.samples || []).length
      ) {
        samples = samples.concat(context.state.entries[id].samples);
      } else {
        let nSamples = Math.floor(Math.random() * 100) + 1;
        let dt = interval.start;
        var status = 0;
        var statusCounter = 4;
        if (nSamples < statusCounter * 2) nSamples = statusCounter * 2;
        for (var i = 0; i <= nSamples; i++) {
          var value =
            type == "boolean" ? status : rnd(data.minimum_value, data.maximum_value);
          if (statusCounter == 0) {
            status = status ? 0 : 1;
            statusCounter = parseInt(Math.random() * 5);
          } else {
            statusCounter -= 1;
          }
          samples.push({
            data_id: id,
            date_time: dt.toISOString(),
            value: value
          });
          dt = dt.add(diffMin / nSamples, "minutes");
        }
        hasNew = true;
      }
    });
    if (hasNew) {
      context.commit("SET_INTERVAL", interval);
      context.commit("SET_PENDING", { ids: ids });
      setTimeout(() => {
        context.commit("SET_CREATED_AT", now);
        generate(context, ids, samples);
      }, 0);
    }
  },
  resetData(context, dataId) {
    context.commit("SET_PENDING", { ids: [dataId] });
    setTimeout(() => {
      context.commit("RESET_DATA", dataId);
    }, 0);
  },
  reset(context) {
    context.commit("SET_PENDING", {
      ids: Object.keys(context.state.entries)
    });
    setTimeout(() => {
      context.commit("RESET");
    }, 0);
  },
  setFilterValues(context, value) {
    let ids = Object.keys(context.state.entries || {});
    if (!ids.length && context?.state?.query?.data_ids) {
      ids = (context.state.query.data_ids || '').split(",");
    }
    if (!ids.length) return;
    let samples = Object.values(context.state.api_samples || {}).reduce(
      (a, c) => a.concat(c),
      []
    );
    // let samples = context.state.samples;
    if (context.state.filter.data_id) {
      context.commit("SET_FILTER_VALUES", value);
      samples = tsFilter(context, context.state.samples);
    } else {
      context.commit("SET_FILTER_VALUES", []);
    }
    context.commit("SET_PENDING", { ids: ids });
    setTimeout(
      () => {
        context.commit("SET_CREATED_AT", moment());
        generate(context, ids, samples);
      },
      10,
      this
    );
  },
  setFilter(context, value) {
    context.commit("SET_FILTER", value);
  }
};

const getters = {
  interval(state) {
    return state.interval;
  },
  createdAt(state) {
    return state.createdAt;
  },
  dataList(state) {
    return (state.dataList || []).map((data) => {
      let item = JSON.parse(JSON.stringify(data));
      item.history = (state.entries || {})[item.id];
      return item;
    });
  },
  entries(state) {
    return state.entries;
  },
  pending(state) {
    return state.pending;
  },
  ready(state) {
    return state?.ready || {};
  },
  defHistoryData() {
    return initHistoryData();
  },
  busy(state) {
    return (state?.pending || []).length > 0;
  },
  filterOptions(state) {
    if (!state.filter.data_id || !state.createdAt) return [];
    return uniq(
      (state.samples || [])
        .filter(({ data_id }) => parseInt(data_id) == state.filter.data_id)
        .map(({ restore_value }) => restore_value)
    ).sort();
  },
  filterValues(state) {
    return state.filter.values || [];
  },
  prefixedPeriods(state) {
    return prefixedPeriods();
  }
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

const createHistory = () => ({
  namespaced: true,
  state: initialState(),
  mutations: mutations,
  actions: actions,
  getters: getters
});

export { fillList, linearInterpolation, createHistory, stats };